import React, { useState } from "react";
import RegisterService from "../../../service/Auth/Register";
import { toast } from "react-toastify";
import "./LoadingOverlay.css";

const FloatingPaymentButton = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let apiservice = new RegisterService();
  const handlePayment = async () => {
    setIsLoading(true);
    await apiservice.createFirstSubscription({}).then((res) => {
      setIsLoading(false);
      if (res.status) {
        window.location.href = res.data.paymentDetails.message ?? "";
      } else {
        toast.error(res?.error?.description ? res?.error?.description : "");
      }
    });
  };

  return (
    <div
      style={styles.floatingButtonContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handlePayment}
    >
      <button style={styles.floatingButton}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
          <line x1="1" y1="10" x2="23" y2="10"></line>
        </svg>
      </button>
      {isHovered && <div style={styles.tooltip}>Make your subscription</div>}
      {isLoading && (
        <div className="loadingoverlay">
          <div className="loadingloader">Loading...</div>
        </div>
      )}
    </div>
  );
};

const styles = {
  floatingButtonContainer: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1000,
    cursor: "pointer",
  },
  floatingButton: {
    backgroundColor: "#28a745", // Green button
    color: "#fff",
    border: "none",
    borderRadius: "50%",
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    cursor: "pointer",
  },
  tooltip: {
    marginTop: "10px",
    backgroundColor: "#000", // Black tooltip background
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
};

export default FloatingPaymentButton;
