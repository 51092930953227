import React from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import companyName from "../../../store/mobxStore/companyName";
import PageTitle from "../../components/common/pageTitle";
//import NewsContentNew from '../../components/tools/NewsContentNew';
import { useLocation, useHistory } from "react-router-dom";

function PayStack(props) {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const trxref = queryParams.get("trxref");
  const reference = queryParams.get("reference");
  console.log({ trxref, reference });
  const { t } = props;
  const goToDashboard = () => {
    history.push("/dashboard");
  };
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.PayStack")}
        </title>
      </Helmet>
      <PageTitle title="payStack" buttonOn={false} />
      <div style={styles.container}>
        <div style={styles.content}>
          <h1>Paystack Payment</h1>
          <p>Transaction Reference: {trxref}</p>
          <p>Reference: {reference}</p>
          <p style={styles.note}>
            We are currently verifying your payment with our backend system.
            Once confirmed, we will update your profile accordingly. In the
            meantime, feel free to navigate to your dashboard.
          </p>
          <button style={styles.button} onClick={goToDashboard}>
            Go to Dashboard
          </button>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f0f0f0",
  },
  content: {
    textAlign: "center",
    padding: "20px",
    backgroundColor: "white",
    borderRadius: "8px",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  note: {
    margin: "20px 0",
    fontStyle: "italic",
  },
  button: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "16px",
  },
};

export default withTranslation()(withRouter(PayStack));
