import React, { useState, useEffect, useCallback, useRef } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FormInputControl, AlertBs } from "../common";
import style from "./payment.module.scss";
import { useTranslation } from "react-i18next";
import Payments from "../../../service/common/PaymentApi";
import { useHistory } from "react-router-dom";
import CheckoutForm from "./CheckoutForm";
import StripeCheckout from "react-stripe-checkout";
import { useSelector } from "react-redux";
import { usePaystackPayment } from 'react-paystack';
import { PAYSTACK_PUBLIC_KEY } from "../../../configuration/apiconfig";
function PayStack(props) {

    console.log("props",props)

  const { t } = useTranslation();
  const history = useHistory();
  const buttonref=useRef('')
  const [clientSecret, setClientSecret] = useState("");
  const [Loading,setLoading]=useState(false)

//   const public_Key = process.env.PAYSTACK_PUB_KEY;

  const config = {
    // reference: (new Date()).getTime().toString(),
    email: props.email,
    amount: parseInt(props.amount)*100,
    publicKey:PAYSTACK_PUBLIC_KEY ,
};
  console.log(config)
  const { currentTheme } = useSelector((state) => state.Theme);
  const { Currency } = useSelector((state) => state.curr);
  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };

  const onSuccess = (reference) => {
    console.log(reference);
    props.submit(buttonref.current,reference, "payment")
  };

 
  const onClose = () => {
    history.push('/logout')
  }
  const initializePayment = usePaystackPayment(config);
//   useEffect(() => {
 
//   }, []);

  const onToken = useCallback((e, token) => {
    if (
      window.location.pathname.includes("/register") ||
      window.location.pathname.includes("/replica_register")
    ) {
      props.submit(e, token, "payment");
    } else {
      props.submit(e, token);
    }
  });

  return (
    <div className={`${style.MianContent}`}>
      <div>
        {
          Loading?
          <pre
          className={`${
            currentTheme == "theme-dark"
              ? ` alert alert-danger`
              : `alert alert-info`
          }`}
        >
          {t("Common.DO_NOT_CLOSE_PAGE")}
        </pre>
        :
         <pre
         className={`${
           currentTheme == "theme-dark"
             ? ` alert alert-danger`
             : `alert alert-info`
         }`}
       >
         {t("Common.clickFinish")}
       </pre>
        }
        <Row>
          <Col md={12}>
            { props.stripeError && props.stripeError.error ? (
              <>
                <div className={style.Buttons}>
                  <AlertBs
                    variant="danger"
                    show="true"
                    message={props.stripeError.message}
                    close="true"
                  />
                  <div className={style.Buttons}>
                    {style.PrevButton && (
                      <Button
                        className={style.PrevButton}
                        onClick={props.prevButtonClick}
                      >
                        {t("profile.prev")}
                      </Button>
                    )}
                     <Button
                        className={style.PrevButton}
                        onClick={props.prevButtonClick}
                      >
                        {t("Button.finish")}
                      </Button>
                   
                  </div>
                </div>
              </>
            ) : 
            Loading ? (
              <div className="spinner-container">
              <div className="loading-spinner"></div>
            </div>
        
            ) : (
             
              <div className={style.Buttons}>
                {style.PrevButton && (
                  <Button
                    disabled={Loading}
                    className={style.PrevButton}
                    onClick={props.prevButtonClick}
                  >
                    {t("profile.prev")}
                  </Button>
                )}
              
        <Button
           disabled={Loading}
           ref={buttonref}
           className={style.PrevButton}
           onClick={()=>{ 
            setLoading(true)
            initializePayment(onSuccess, onClose)
          }}
        >
              {t("Button.finish")}
        </Button>
    </div>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default PayStack;
